import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import AssetIdTextField from "../../../components/FormFields/AssetIdTextField/AssetIdTextField";
import { FormFieldText } from "../../../components/FormFields/FormFieldText";
import { FormFieldSelect } from "../../../components/FormFields/FormFieldSelect";
import ScannedAssetTextField from "../../../components/FormFields/ScannedAssetTextField/ScannedAssetTextField";
import { FormFieldAssets } from "../FormFieldAssets";
import { FormFieldQAAssets } from "../FormFieldQAAssets";
import { FormFieldBarcode } from "../FormFieldBarcode";
import {
  turnAssetIntoAContainerAsset,
  submitEditAsset,
} from "../../../utils/api";

export default function SingleFormPanel(props) {
  const {
    action,
    asset,
    binLocation,
    classificationMap,
    dispatch,
    form,
    formName,
    forms,
    handleSnackBar,
    setForm,
    token,
  } = props;
  const [assetInfo, setAssetInfo] = useState({});
  const [formFields, setFormFields] = useState({});
  const [state, setState] = useState({
    applyActiveTagChecked: false,
    assetIdUsedInHack: "",
    externalId: null,
    navigateToEventListPage: false,
    snackbarShow: false,
    snackbarText: "Success",
    snackbarSeverity: "success",
  });
  const { assetIdUsedInHack } = state;
  // Currently a Hack. We need a mechanism on forms to determine if the submit button
  // is shown or not.
  const showSubmitButton = formName !== "Item Information";

  function handleFieldValue(fieldIndex, fieldValue, allowMultiple) {
    setFormFields((prevState) => {
      prevState[fieldIndex].value = fieldValue;

      return prevState;
    });
  }

  const fieldComps = Object.keys(formFields).map((formField, index) => {
    const specificField = formFields[formField];
    const { fieldType, fieldKey, fieldValue, formOptions, label, readOnly } =
      specificField;

    // Here we check to see if the field is a text field AND an assetId field.
    // We have to do special steps with these fields, so they broken out of the switch
    // statement
    if (fieldType === "text" && fieldKey === "assetId") {
      return (
        <AssetIdTextField
          assetInfo={assetInfo}
          key={index}
          fieldIndex={index}
          fieldKey={fieldKey}
          formFields={formFields}
          label={label}
          onChange={(e) => {
            // We follow a seperate flow for forms with assemblyIds in the onSubmit
            // I believe the logic above to be faulty. At some point this was valid.
            // but is no longer true. Need to validate that this is invalid
            if (fieldKey === "assemblyId") {
              setState((prevState) => ({
                ...prevState,
                externalId: e.target.value,
              }));
            }
            setFormFields((prevState) => ({
              ...prevState,
              [formField]: {
                ...prevState[formField],
                fieldValue: e.target.value,
              },
            }));
          }}
          onClear={() => {
            // Clear out the assetInfo
            setAssetInfo({});

            // Clear out any value this might of had
            setFormFields((prevState) => ({
              ...prevState,
              [formField]: { ...prevState[formField], fieldValue: "" },
            }));
          }}
          readOnly={readOnly}
          setAssetInfo={setAssetInfo}
          token={token}
          value={formFields[index].fieldValue}
        />
      );
    }

    switch (fieldType) {
      case "externalAssembly":
        return (
          <FormFieldText
            key={index}
            fieldIndex={index}
            label={label}
            onChange={(e) => {
              // We follow a seperate flow for forms with assemblyIds in the onSubmit
              // I believe the logic above to be faulty. At some point this was valid.
              // but is no longer true. Need to validate that this is invalid
              if (fieldKey === "assemblyId") {
                setState((prevState) => ({
                  ...prevState,
                  externalId: e.target.value,
                }));
              }

              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: e.target.value,
                },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            value={formFields[index].fieldValue}
          />
        );
      case "dissociate":
        return (
          <FormFieldText
            key={index}
            fieldIndex={index}
            label={label}
            onChange={(e) => {
              // We follow a seperate flow for forms with assemblyIds in the onSubmit
              // I believe the logic above to be faulty. At some point this was valid.
              // but is no longer true. Need to validate that this is invalid
              if (fieldKey === "assemblyId") {
                setState((prevState) => ({
                  ...prevState,
                  externalId: e.target.value,
                }));
              }

              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: e.target.value,
                },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            value={formFields[index].fieldValue}
          />
        );
      case "text":
        return (
          <FormFieldText
            key={index}
            fieldIndex={index}
            label={label}
            onChange={(e) => {
              // We follow a seperate flow for forms with assemblyIds in the onSubmit
              // I believe the logic above to be faulty. At some point this was valid.
              // but is no longer true. Need to validate that this is invalid
              if (fieldKey === "assemblyId") {
                setState((prevState) => ({
                  ...prevState,
                  externalId: e.target.value,
                }));
              }

              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: e.target.value,
                },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            value={formFields[index].fieldValue}
          />
        );
      case "select":
        return (
          <FormFieldSelect
            defaultValue={fieldValue}
            fieldIndex={index}
            key={`${index} - ${fieldType}`}
            label={label}
            onChange={(e) => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: e.target.value,
                },
              }));
            }}
            options={formOptions}
            readOnly={readOnly}
            value={formFields[index].fieldValue || ""}
          />
        );
      case "number":
        return (
          <FormFieldText
            fieldIndex={index}
            key={index}
            label={label}
            multiline={false}
            onChange={(e) => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: parseInt(e.target.value),
                },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            type="number"
            value={formFields[index].fieldValue}
          />
        );

      // Note: allowMultiple can be null, true or false
      //   null: a single value will be written into the fieldValue
      //   true: multiple values may be added to the fieldValues array
      //   false: a single value is maintained in the fieldValues array
      //
      case "scannedAssets":
        return (
          <ScannedAssetTextField
            assetInfo={assetInfo}
            key={index}
            fieldIndex={index}
            fieldKey={fieldKey}
            formFields={formFields}
            label={label}
            isHack={formName === "Create Container"}
            onChange={(event) => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: event.target.value,
                },
              }));
            }}
            onClear={() => {
              // Clear out the assetInfo
              setAssetInfo({});

              // Clear out any value this might of had
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            setAssetInfo={setAssetInfo}
            setState={setState}
            token={token}
            value={formFields[index].fieldValue}
          />
        );

      case "associate":
        break;
      case "externalId":
        return (
          <FormFieldAssets
            allowMultiple={false}
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a Barcode using the WebCam"
          />
        );
      case "scanQA":
        return (
          <FormFieldQAAssets
            allowMultiple={false}
            binLocation={binLocation}
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a Barcode using the WebCam"
          />
        );
      case "location":
        return (
          <FormFieldText
            key={index}
            fieldIndex={index}
            label={label}
            onChange={(e) => {
              // We follow a seperate flow for forms with assemblyIds in the onSubmit
              // I believe the logic above to be faulty. At some point this was valid.
              // but is no longer true. Need to validate that this is invalid
              if (fieldKey === "assemblyId") {
                setState((prevState) => ({
                  ...prevState,
                  externalId: e.target.value,
                }));
              }

              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: e.target.value,
                },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            value={formFields[index].fieldValue}
          />
        );
      case "externalSku":
        return (
          <FormFieldBarcode
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a SKU Barcode using the WebCam"
          />
        );
      default:
        console.log("***** can't process field type: " + formField.fieldType);
        break;
    }
    return null;
  });

  function editAsset() {
    submitEditAsset({
      action,
      formFields,
      token,
    }).then((res) => {
      if (res.success) {
        // We need to clear all the formFieldValues
        setFormFields((prevState) => {
          let newState = { ...prevState };
          Object.keys(prevState).forEach((item) => {
            const specificField = newState[item];

            specificField.fieldValue = "";
          });

          return newState;
        });

        // Also need to clear out the assetInfo
        setAssetInfo({});

        // We need to throw the success modal up. Also need to null the externalId out
        setState((prevState) => ({ ...prevState, externalId: null }));
        handleSnackBar({ severity: "success", text: "Success" });
      } else {
        handleSnackBar({
          severity: "error",
          text: `Something went wrong. ${res?.error ? res?.error : ""}`,
        });
      }
    });
  }

  // The useEffect grabs the selected forms fieldList and throws it into state
  useEffect(() => {
    if (action && forms && !form) {
      const formId = action.formId;

      // Grabbing the selected form
      const formTemp = forms.find((f) => f.formId === formId);
      // This statement sets up the formFields
      if (formTemp) {
        dispatch(setForm(formTemp));

        // Does the form have fields?
        if (formTemp.fields) {
          const collectionOfFields = {};

          formTemp.fields.forEach((field, index) => {
            const fieldCopy = { ...field };
            // We first check to see if the item is comign from a classification
            if (
              fieldCopy.fieldKey === "classificationMap" &&
              fieldCopy.topLevel &&
              classificationMap
            ) {
              const associatedClassification =
                asset.classificationMap[fieldCopy.topLevel];
              if (associatedClassification) {
                const fo = field.formOptions.find((fo) => {
                  return fo.label === associatedClassification;
                });

                if (fo) {
                  fieldCopy.fieldValue = fo.formOptionId;
                }
              }
            }

            // Setting the default value
            fieldCopy["fieldValue"] = "";

            collectionOfFields[index] = fieldCopy;
          });
          setFormFields(collectionOfFields);
        }
      }
    }
  }, [
    action,
    asset?.classificationMap,
    classificationMap,
    dispatch,
    form,
    forms,
    setForm,
  ]);

  return (
    <Grid container>
      {/* Form Fields */}
      <Grid item xs={12}>
        {fieldComps ? fieldComps : null}
      </Grid>

      {/* Buttons Container */}
      <Box
        backgroundColor="white"
        bottom="0"
        display="flex"
        justifyContent="space-between"
        position="sticky"
        width="100%"
      >
        {/* Submit Button */}
        {showSubmitButton ? (
          <Grid item xs={4}>
            <Button
              // Has any assetInfo been appended yet?
              disabled={assetInfo.success === undefined ? true : false}
              fullWidth
              onClick={() => {
                // This if statement is a lil dirty hack. Until the backend can provide us
                // a way to create container assets. We will have to manually do this.
                if (formName === "Create Container") {
                  turnAssetIntoAContainerAsset({
                    token,
                    assetId: assetIdUsedInHack,
                  }).then((res) => {
                    if (res.success) {
                      editAsset();
                    } else {
                      handleSnackBar(
                        "error",
                        `Unable to Create Container. Contact System Admin. ${
                          res?.error ? res?.error : ""
                        }`
                      );
                    }
                  });
                } else {
                  editAsset();
                }
              }}
              sx={{ mt: 3, mb: 2 }}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        ) : null}

        {/* Clear Form Button */}
        <Grid item xs={4}>
          <Button
            fullWidth
            onClick={() => {
              setState({
                applyActiveTagChecked: false,
                externalId: null,
                navigateToEventListPage: false,
                snackbarShow: false,
              });

              // We need to clear all the formFieldValues
              setFormFields((prevState) => {
                let newState = { ...prevState };
                Object.keys(prevState).forEach((item) => {
                  const specificField = newState[item];

                  specificField.fieldValue = "";
                });

                return newState;
              });

              // Also need to clear out the assetInfo
              setAssetInfo({});
            }}
            sx={{ mt: 3, mb: 2 }}
            variant="contained"
          >
            CLEAR
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
}
