import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import AssetIdTextField from "../../../components/FormFields/AssetIdTextField/AssetIdTextField";
import { FormFieldText } from "../../../components/FormFields/FormFieldText";
import { FormFieldSelect } from "../../../components/FormFields/FormFieldSelect";
import ScannedAssetTextField from "../../../components/FormFields/ScannedAssetTextField/ScannedAssetTextField";
import { FormFieldAssets } from "../FormFieldAssets";
import { FormFieldQAAssets } from "../FormFieldQAAssets";
import { FormFieldBarcode } from "../FormFieldBarcode";
import {
  turnAssetIntoAContainerAsset,
  submitEditAsset,
} from "../../../utils/api";
import ViewScans from "./ViewScans";

export default function MultipleFormPanel2(props) {
  const {
    action,
    asset,
    binLocation,
    classificationMap,
    dispatch,
    form,
    formName,
    forms,
    handleSnackBar,
    setForm,
    snackbarShow,
    token,
  } = props;
  const [assetInfo, setAssetInfo] = useState({});
  const [scanCollection, setScanCollection] = useState([]);
  const [formFields, setFormFields] = useState({});
  const [staticFormFields, setStaticFormFields] = useState({});
  const [defaultFormValues, setDefaultFormValues] = useState({});
  const [state, setState] = useState({
    applyActiveTagChecked: false,
    assetIdUsedInHack: "",
    externalId: null,
    navigateToEventListPage: false,
    snackbarShow: false,
    snackbarText: "Success",
    snackbarSeverity: "success",
  });
  const { assetIdUsedInHack } = state;
  const [viewScansDialogShow, setViewScansDialogShow] = useState(false);
  let hasScans = scanCollection.length === 0;
  // Currently a Hack. We need a mechanism on forms to determine if the submit button
  // is shown or not.
  const showButtons = formName !== "Item Information";

  function handleFieldValue(fieldIndex, fieldValue, allowMultiple) {
    setFormFields((prevState) => {
      prevState[fieldIndex].value = fieldValue;

      return prevState;
    });
  }

  const fieldComps = (fieldsArray = {}, isStatic = false) => {
    const renderedFields = Object.keys(fieldsArray).map((formField, index) => {
      const specificField = !isStatic
        ? formFields[formField]
        : staticFormFields[formField];
      const { fieldType, fieldKey, fieldValue, formOptions, label, readOnly } =
        specificField;
      const value = isStatic
        ? staticFormFields[index]?.fieldValue
        : formFields[index].fieldValue;

      function onChange(e) {
        const { assetInfo = null, selectionLabel = null, value } = e.target;
        if (isStatic) {
          setStaticFormFields((prevState) => ({
            ...prevState,
            [formField]: {
              ...prevState[formField],
              assetInfo: assetInfo,
              fieldValue: value,
              selectionLabel: selectionLabel,
            },
          }));
        } else {
          setFormFields((prevState) => ({
            ...prevState,
            [formField]: {
              ...prevState[formField],
              assetInfo: assetInfo,
              fieldValue: value,
              selectionLabel: selectionLabel,
            },
          }));
        }
      }

      // Here we check to see if the field is a text field AND an assetId field.
      // We have to do special steps with these fields, so they broken out of the switch
      // statement
      if (fieldType === "text" && fieldKey === "assetId") {
        return (
          <AssetIdTextField
            assetInfo={assetInfo}
            key={index}
            fieldIndex={index}
            fieldKey={fieldKey}
            formFields={formFields}
            label={label}
            onChange={(e) => {
              // We follow a seperate flow for forms with assemblyIds in the onSubmit
              // I believe the logic above to be faulty. At some point this was valid.
              // but is no longer true. Need to validate that this is invalid
              if (fieldKey === "assemblyId") {
                setState((prevState) => ({
                  ...prevState,
                  externalId: e.target.value,
                }));
              }
              onChange(e);
            }}
            onClear={() => {
              // Clear out the assetInfo
              setAssetInfo({});

              // Clear out any value this might of had
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            setAssetInfo={setAssetInfo}
            token={token}
            value={value}
          />
        );
      }

      switch (fieldType) {
        case "externalAssembly":
          return (
            <FormFieldText
              key={index}
              fieldIndex={index}
              label={label}
              onChange={(e) => {
                // We follow a seperate flow for forms with assemblyIds in the onSubmit
                // I believe the logic above to be faulty. At some point this was valid.
                // but is no longer true. Need to validate that this is invalid
                if (fieldKey === "assemblyId") {
                  setState((prevState) => ({
                    ...prevState,
                    externalId: e.target.value,
                  }));
                }
                onChange(e);
              }}
              onClear={() => {
                setFormFields((prevState) => ({
                  ...prevState,
                  [formField]: { ...prevState[formField], fieldValue: "" },
                }));
              }}
              readOnly={readOnly}
              value={value}
            />
          );
        case "dissociate":
          return (
            <FormFieldText
              key={index}
              fieldIndex={index}
              label={label}
              onChange={(e) => {
                // We follow a seperate flow for forms with assemblyIds in the onSubmit
                // I believe the logic above to be faulty. At some point this was valid.
                // but is no longer true. Need to validate that this is invalid
                if (fieldKey === "assemblyId") {
                  setState((prevState) => ({
                    ...prevState,
                    externalId: e.target.value,
                  }));
                }

                onChange(e);
              }}
              onClear={() => {
                setFormFields((prevState) => ({
                  ...prevState,
                  [formField]: { ...prevState[formField], fieldValue: "" },
                }));
              }}
              readOnly={readOnly}
              value={value}
            />
          );
        case "text":
          return (
            <FormFieldText
              key={index}
              fieldIndex={index}
              label={label}
              onChange={(e) => {
                // We follow a seperate flow for forms with assemblyIds in the onSubmit
                // I believe the logic above to be faulty. At some point this was valid.
                // but is no longer true. Need to validate that this is invalid
                if (fieldKey === "assemblyId") {
                  setState((prevState) => ({
                    ...prevState,
                    externalId: e.target.value,
                  }));
                }

                onChange(e);

                setFormFields((prevState) => ({
                  ...prevState,
                  [formField]: {
                    ...prevState[formField],
                    fieldValue: e.target.value,
                  },
                }));
              }}
              onClear={() => {
                setFormFields((prevState) => ({
                  ...prevState,
                  [formField]: { ...prevState[formField], fieldValue: "" },
                }));
              }}
              readOnly={readOnly}
              value={formFields[index].fieldValue}
            />
          );
        case "select":
          return (
            <FormFieldSelect
              defaultValue={fieldValue}
              fieldIndex={index}
              key={`${index} - ${fieldType}`}
              label={label}
              onChange={(e) => {
                let selectionObject = formOptions.find(
                  (element) => element.formOptionId === e.target.value
                );
                const { label = "" } = selectionObject;

                onChange({
                  target: { value: e.target.value, selectionLabel: label },
                });
                // setFormFields((prevState) => ({
                //   ...prevState,
                //   [formField]: {
                //     ...prevState[formField],
                //     fieldValue: e.target.value,
                //     selectionLabel: label,
                //   },
                // }));
              }}
              options={formOptions}
              readOnly={readOnly}
              value={value || ""}
            />
          );
        case "number":
          return (
            <FormFieldText
              fieldIndex={index}
              key={index}
              label={label}
              multiline={false}
              onChange={(e) => {
                onChange(e);
              }}
              onClear={() => {
                setFormFields((prevState) => ({
                  ...prevState,
                  [formField]: { ...prevState[formField], fieldValue: "" },
                }));
              }}
              readOnly={readOnly}
              type="number"
              value={value}
            />
          );

        // Note: allowMultiple can be null, true or false
        //   null: a single value will be written into the fieldValue
        //   true: multiple values may be added to the fieldValues array
        //   false: a single value is maintained in the fieldValues array
        //
        case "scannedAssets":
          return (
            <ScannedAssetTextField
              assetInfo={assetInfo}
              key={index}
              fieldIndex={index}
              fieldKey={fieldKey}
              formFields={formFields}
              label={label}
              isHack={formName === "Create Container"}
              onChange={(event) => {
                onChange(event);
              }}
              onClear={() => {
                // Clear out the assetInfo
                setAssetInfo({});

                // Clear out any value this might of had
                setFormFields((prevState) => ({
                  ...prevState,
                  [formField]: { ...prevState[formField], fieldValue: "" },
                }));
              }}
              readOnly={readOnly}
              setAssetInfo={setAssetInfo}
              setState={setState}
              token={token}
              value={value}
            />
          );

        case "associate":
          break;
        case "externalId":
          return (
            <FormFieldAssets
              allowMultiple={false}
              buttonLabel="Scan Code"
              fieldIndex={index}
              handleFieldValue={handleFieldValue}
              key={index}
              label="Scan a Barcode using the WebCam"
            />
          );
        case "scanQA":
          return (
            <FormFieldQAAssets
              allowMultiple={false}
              binLocation={binLocation}
              buttonLabel="Scan Code"
              fieldIndex={index}
              handleFieldValue={handleFieldValue}
              key={index}
              label="Scan a Barcode using the WebCam"
            />
          );
        case "location":
          return (
            <FormFieldText
              key={index}
              fieldIndex={index}
              label={label}
              onChange={(e) => {
                // We follow a seperate flow for forms with assemblyIds in the onSubmit
                // I believe the logic above to be faulty. At some point this was valid.
                // but is no longer true. Need to validate that this is invalid
                if (fieldKey === "assemblyId") {
                  setState((prevState) => ({
                    ...prevState,
                    externalId: e.target.value,
                  }));
                }
                onChange(e);
              }}
              onClear={() => {
                setFormFields((prevState) => ({
                  ...prevState,
                  [formField]: { ...prevState[formField], fieldValue: "" },
                }));
              }}
              readOnly={readOnly}
              value={value}
            />
          );
        case "externalSku":
          return (
            <FormFieldBarcode
              buttonLabel="Scan Code"
              fieldIndex={index}
              handleFieldValue={handleFieldValue}
              key={index}
              label="Scan a SKU Barcode using the WebCam"
            />
          );
        default:
          console.log("***** can't process field type: " + formField.fieldType);
          break;
      }
      return null;
    });

    return renderedFields;
  };

  function editAsset() {
    submitEditAsset({
      action,
      formFields,
      staticFormFields,
      token,
    }).then((res) => {
      if (res.success) {
        // We need to clear all values
        clearAllValues();

        // Also need to clear out the assetInfo
        setAssetInfo({});

        // We need to throw the success modal up. Also need to null the externalId out
        setState((prevState) => ({ ...prevState, externalId: null }));
        handleSnackBar({ severity: "success", text: "Success" });
      } else {
        handleSnackBar({
          severity: "error",
          text: `Something went wrong. ${res?.error ? res?.error : ""}`,
        });
      }
    });
  }

  function clearValues() {
    setFormFields(defaultFormValues);
  }

  function clearAllValues() {
    setFormFields((prevState) => {
      let newState = { ...prevState };
      Object.keys(prevState).forEach((item) => {
        const specificField = newState[item];

        specificField.fieldValue = "";
      });

      return newState;
    });

    setStaticFormFields((prevState) => {
      let newState = { ...prevState };
      Object.keys(prevState).forEach((item) => {
        const specificField = newState[item];

        specificField.fieldValue = "";
      });

      return newState;
    });
  }

  // The useEffect grabs the selected forms fieldList and throws it into state
  useEffect(() => {
    if (action && forms && !form) {
      const formId = action.formId;

      // Grabbing the selected form
      const formTemp = forms.find((f) => f.formId === formId);

      // This statement sets up the formFields
      if (formTemp) {
        const { fields = [], multiUseFields = [] } = formTemp;

        // Previously we had forms.fields, but have since added forms.multiUseFields
        // This is for forms that would have a static value in them.
        // Thus, if a form does not have this capability fields and staticFields below
        // will be null
        const { fields: fieldsFromMultiUse, staticFields } = multiUseFields;
        const fieldsToRender = fieldsFromMultiUse ? fieldsFromMultiUse : fields;
        dispatch(setForm(formTemp));

        function prepFields(arrayOfFields) {
          let collectionOfFields = {};

          arrayOfFields.forEach((field, index) => {
            const fieldCopy = { ...field };

            // We first check to see if the item is comign from a classification
            if (
              fieldCopy.fieldKey === "classificationMap" &&
              fieldCopy.topLevel &&
              classificationMap
            ) {
              const associatedClassification =
                asset.classificationMap[fieldCopy.topLevel];
              if (associatedClassification) {
                const fo = field.formOptions.find((fo) => {
                  return fo.label === associatedClassification;
                });

                if (fo) {
                  fieldCopy.fieldValue = fo.formOptionId;
                }
              }
            }

            // Setting the default value
            fieldCopy["fieldValue"] = "";

            collectionOfFields[index] = fieldCopy;
          });
          return collectionOfFields;
        }

        // Does the form have fields?
        if (fieldsToRender || staticFields) {
          const collectionOfFields = prepFields(fieldsToRender);
          let collectionOfStaticFields = {};

          if (staticFields) {
            collectionOfStaticFields = prepFields(staticFields);
          }

          setStaticFormFields(collectionOfStaticFields);
          setFormFields(collectionOfFields);
          setDefaultFormValues(collectionOfFields);
        }
      }
    }
  }, [
    action,
    asset?.classificationMap,
    classificationMap,
    dispatch,
    form,
    forms,
    setForm,
  ]);

  return (
    <Grid container>
      {/* View Scans Dialog */}
      <ViewScans
        action={action}
        handleSnackBar={handleSnackBar}
        open={viewScansDialogShow}
        scanCollection={scanCollection}
        setOpen={setViewScansDialogShow}
        setScanCollection={setScanCollection}
        snackbarShow={snackbarShow}
        token={token}
      />

      {/* Clear Form Button */}
      <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button
          onClick={() => {
            setState({
              applyActiveTagChecked: false,
              externalId: null,
              navigateToEventListPage: false,
              snackbarShow: false,
            });

            // We need to clear all the formFieldValues
            clearAllValues();

            // Also need to clear out the assetInfo
            setAssetInfo({});
          }}
          sx={{ mt: 0, mb: 0 }}
          variant="text"
        >
          CLEAR FIELDS
        </Button>
      </Grid>

      {/* Form Fields */}
      <Grid item xs={12}>
        {fieldComps(staticFormFields, true)}
        {fieldComps(formFields)}
      </Grid>

      {/* Buttons Container */}
      {showButtons ? (
        <Box
          backgroundColor="white"
          bottom="0"
          display="flex"
          justifyContent="space-between"
          position="sticky"
          width="100%"
        >
          {/* Save Button */}
          <Grid item xs={4}>
            <Button
              fullWidth
              disabled={assetInfo.success === undefined ? true : false}
              onClick={() => {
                // TODO - Should probably break this logic out into a utils file or something
                // Need to grab the deviceId from the formFields
                const currentDevice =
                  Object.values(formFields).find(
                    (element) => element.fieldKey === "assetId"
                  ) || {};
                const currentDeviceId = currentDevice?.fieldValue;
                let hasDuplicate = false;

                //Here we iterate through the collected scans
                scanCollection.forEach((scan) => {
                  const scanValues = Object.values(scan.formFields) || {};
                  const scanDevice = scanValues.find(
                    (element) => element.fieldKey === "assetId"
                  );
                  const scanDeviceId = scanDevice?.fieldValue || null;

                  if (scanDeviceId === currentDeviceId) {
                    hasDuplicate = true;
                  }
                });

                if (hasDuplicate) {
                  handleSnackBar({
                    severity: "error",
                    text: "You have a duplicate device already stored",
                  });
                } else {
                  setScanCollection((prevState) => [
                    ...prevState,
                    { formFields, staticFormFields },
                  ]);
                  clearValues();
                  setState({
                    applyActiveTagChecked: false,
                    externalId: null,
                    navigateToEventListPage: false,
                    snackbarShow: false,
                  });

                  // Also need to clear out the assetInfo
                  setAssetInfo({});
                }
              }}
              sx={{ mt: 3, mb: 2 }}
              variant="contained"
            >
              Save
            </Button>
          </Grid>

          {/* View Scans and Submit Button - Only appears is user has no scans saved */}
          {!hasScans ? (
            <Grid item xs={5}>
              <Button
                fullWidth
                onClick={() => {
                  setViewScansDialogShow(true);
                }}
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
              >
                View Scans
              </Button>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Button
                // Has any assetInfo been appended yet?
                disabled={assetInfo.success === undefined ? true : false}
                fullWidth
                onClick={() => {
                  // This if statement is a lil dirty hack. Until the backend can provide us
                  // a way to create container assets. We will have to manually do this.
                  if (formName === "Create Container") {
                    turnAssetIntoAContainerAsset({
                      token,
                      assetId: assetIdUsedInHack,
                    }).then((res) => {
                      if (res.success) {
                        editAsset();
                      } else {
                        handleSnackBar(
                          "error",
                          `Unable to Create Container. Contact System Admin. ${
                            res?.error ? res?.error : ""
                          }`
                        );
                      }
                    });
                  } else {
                    editAsset();
                  }
                }}
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
              >
                Submit
              </Button>
            </Grid>
          )}
        </Box>
      ) : null}
    </Grid>
  );
}
