import { useEffect, useState } from "react";
import {
  selectAction,
  selectAsset,
  selectForm,
  selectLocation,
  setForm,
  selectReceivePanelAssetId,
  assignReceivePanelAssetId,
} from "../panelSlice";
import { Box, Button, Grid } from "@mui/material";
import { externalScanFromApp } from "../../../utils/api";
import { FormFieldAssets } from "../FormFieldAssets";
import { FormFieldBarcode } from "../FormFieldBarcode";
import { FormFieldQAAssets } from "../FormFieldQAAssets";
import { FormFieldSelect } from "../../../components/FormFields/FormFieldSelect";
import { FormFieldText } from "../../../components/FormFields/FormFieldText";
import { Navigate } from "react-router-dom";
import { selectForms } from "../../auth/authSlice";
import { cancelScan, receiveFormSubmitEditAsset } from "../../../utils/api";
import { useSelector, useDispatch } from "react-redux";
import AssetIdTextField from "../../../components/FormFields/AssetIdTextField/AssetIdTextField";
import MaterialSnackbar from "../../../components/MaterialSnackbar";

export default function ReceivePanel(props) {
  const { token } = props;
  const [formFields, setFormFields] = useState({});
  const [state, setState] = useState({
    applyActiveTagChecked: false,
    itemLabel: "",
    navigateToEventListPage: false,
    showFormFields: false,
    snackbarShow: false,
    snackbarText: "",
    snackbarSeverity: "success",
  });
  const [assetInfo, setAssetInfo] = useState({});
  const action = useSelector(selectAction);
  const asset = useSelector(selectAsset);

  // By throwing the assetId up into the redux state, we can check to see if the user
  // never finished the receive form. For instance, they scanned an item and then navigated backwards from their browser.
  const assetIdFromToddsEndpoint = useSelector(selectReceivePanelAssetId);
  const binLocation = useSelector(selectLocation);
  const classificationMap = asset ? asset.classificationMap : null;
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const forms = useSelector(selectForms);
  const {
    itemLabel,
    navigateToEventListPage,
    showFormFields,
    snackbarShow,
    snackbarText,
    snackbarSeverity,
  } = state;

  function handleFieldValue(fieldIndex, fieldValue, allowMultiple) {
    setFormFields((prevState) => {
      prevState[fieldIndex].value = fieldValue;

      return prevState;
    });
  }

  const fieldComps = Object.keys(formFields || {}).map((f, index) => {
    const specificField = formFields[f];
    const { fieldType, fieldKey, fieldValue, formOptions, label, readOnly } =
      specificField;

    // Here we check to see if the field is a text field AND an assetId field.
    // We have to do special steps with these fields, so they broken out of the switch
    // statement
    if (fieldType === "text" && fieldKey === "assetId") {
      return (
        <AssetIdTextField
          assetInfo={assetInfo}
          key={index}
          fieldIndex={index}
          formFields={formFields}
          label={label}
          onChange={(e) => {
            // We follow a seperate flow for forms with assemblyIds in the onSubmit
            if (fieldKey === "assemblyId") {
              setState((prevState) => ({
                ...prevState,
                externalId: e.target.value,
              }));
            }
            setFormFields((prevState) => ({
              ...prevState,
              [f]: { ...prevState[f], fieldValue: e.target.value },
            }));
          }}
          onClear={() => {
            // Clear out the assetInfo
            setAssetInfo({});

            // Clear out any value this might of had
            setFormFields((prevState) => ({
              ...prevState,
              [f]: { ...prevState[f], fieldValue: "" },
            }));
          }}
          readOnly={readOnly}
          setAssetInfo={setAssetInfo}
          token={token}
          value={formFields[index].fieldValue}
        />
      );
    }

    switch (specificField.fieldType) {
      case "text":
        return (
          <FormFieldText
            key={index}
            fieldIndex={index}
            label={label}
            onChange={(e) => {
              setFormFields((prevState) => ({
                ...prevState,
                [f]: { ...prevState[f], fieldValue: e.target.value },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [f]: { ...prevState[f], fieldValue: "" },
              }));
            }}
            multiline={fieldKey === "description" ? true : false}
            readOnly={readOnly}
            value={formFields[index]?.fieldValue}
          />
        );
      case "select":
        return (
          <FormFieldSelect
            defaultValue={fieldValue}
            fieldIndex={index}
            key={index}
            label={label}
            onChange={(e) => {
              setFormFields((prevState) => ({
                ...prevState,
                [f]: { ...prevState[f], fieldValue: e.target.value },
              }));
            }}
            options={formOptions}
            readOnly={readOnly}
          />
        );
      case "number":
        return (
          <FormFieldText
            fieldIndex={index}
            key={index}
            label={label}
            multiline={false}
            onChange={(e) => {
              setFormFields((prevState) => ({
                ...prevState,
                [f]: { ...prevState[f], fieldValue: e.target.value },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [f]: { ...prevState[f], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            type="number"
            value={formFields[index]?.fieldValue}
          />
        );

      // Note: allowMultiple can be null, true or false
      //   null: a single value will be written into the fieldValue
      //   true: multiple values may be added to the fieldValues array
      //   false: a single value is maintained in the fieldValues array
      //
      case "scannedAssets":
        return (
          <FormFieldAssets
            allowMultiple={true}
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a Child Asset using the WebCam"
          />
        );

      case "associate":
        break;
      case "dissociate":
        break;
      case "externalId":
        return (
          <FormFieldAssets
            allowMultiple={false}
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a Barcode using the WebCam"
          />
        );
      case "scanQA":
        return (
          <FormFieldQAAssets
            allowMultiple={false}
            binLocation={binLocation}
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a Barcode using the WebCam"
          />
        );
      case "location":
        return (
          <FormFieldBarcode
            buttonLabel="Scan Location"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a Location Barcode using the WebCam"
          />
        );
      case "externalSku":
        return (
          <FormFieldBarcode
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a SKU Barcode using the WebCam"
          />
        );
      default:
        console.log("***** can't process field type: " + f.fieldType);
        break;
    }
    return null;
  });

  function clearFormFields() {
    // Clear out any assetInfo that might of been set
    setAssetInfo({});

    // Clear out the form field values
    setFormFields((prevState) => {
      let newState = { ...prevState };
      Object.keys(prevState).forEach((item) => {
        const specificField = newState[item];

        specificField.fieldValue = "";
      });

      return newState;
    });
  }

  function handleSnackbar(
    severity = "success",
    text = "Success",
    showFormFields = false
  ) {
    setState((prevState) => ({
      ...prevState,
      snackbarSeverity: severity,
      snackbarShow: true,
      snackbarText: text,
    }));

    // We need to take the success modal down
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, snackbarShow: false }));
    }, 1000);
  }

  function handleSubmit() {
    receiveFormSubmitEditAsset({
      action,
      assetIdFromExternalScan: assetIdFromToddsEndpoint,
      formAction: "Receive",
      formFields,
      token,
    }).then((res) => {
      const { success } = res;

      if (success) {
        // We need to clear the assetId in redux
        dispatch(assignReceivePanelAssetId(""));

        // We need to throw the success modal up - We also need to clear the set the component back to default
        setState((prevState) => ({
          ...prevState,
          assetId: "",
          itemLabel: "",
          showFormFields: false,
        }));

        handleSnackbar("success", "Success");

        // We need to take the success modal down
        setTimeout(() => {
          setState((prevState) => ({ ...prevState, snackbarShow: false }));
        }, 1000);

        // We need to clear all the formFieldValues - I shouldnt have to put this in a timeout. But for whatever reason
        // this is firing off before the res.success is getting here and clearing out the formField values before the api can fire off.
        setTimeout(() => {
          clearFormFields();
        }, 2000);
      } else {
        handleSnackbar(
          "error",
          `Something went wrong. ${res?.error ? res?.error : ""}`
        );
      }
    });
  }

  function setValues(payload) {
    const {
      asset = {},
      error,
      success = false,
      costpointMetaData = {},
    } = payload;
    const { assetId } = asset;
    const itemsWeNeedToRender = [
      "applyActiveTag",
      "description",
      "itemNumber",
      "invUOM",
      "quantity.quantityCurrent,",
    ];

    if (success) {
      // We are throwing the assetId into redux state as well. This is to accomodate for the instance
      // a user hits the back button or refreshes the page. We need to delete the asset thats created.
      dispatch(assignReceivePanelAssetId(assetId));

      setFormFields((prevState) => {
        const newState = { ...prevState };
        const formFieldAsArrayByKey = Object.keys(newState);

        // We traverse the formFields Object
        formFieldAsArrayByKey.forEach((field) => {
          // Is one of the values included in the api response?
          if (itemsWeNeedToRender.includes(formFields[field].fieldKey)) {
            // If so, set the value to whatever came back from the response
            newState[field] = {
              ...newState[field],
              readOnly: true,
              fieldValue: costpointMetaData[formFields[field].fieldKey],
            };
          }
        });
        return newState;
      });

      setState((prevState) => ({
        ...prevState,
        assetId,
        showFormFields: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        snackbarShow: true,
        snackbarText: error,
        snackbarSeverity: "error",
      }));
    }
  }

  function deleteAssetIdFromFormFields() {
    setFormFields((prevState) => {
      let newFormFields = { ...prevState };
      // need to parse through the formfiends and find one that has isAssetId and pop it off
      Object.keys(newFormFields).forEach((item) => {
        if (newFormFields[item].isAssetId) {
          delete newFormFields[item];
        }
      });

      return newFormFields;
    });
  }

  function cancelAction() {
    // Need to delete asset that was created from Todds endpoint
    cancelScan({ action, assetId: assetIdFromToddsEndpoint, token }).then(
      (res) => {
        if (res.success) {
          // Need to set itemLabel back to default, showFormfields to false, and assetId back to empty string
          setState((prevState) => ({
            ...prevState,
            assetId: "",
            itemLabel: "",
            showFormFields: false,
          }));

          // Need to delete the assetId from the formFields
          deleteAssetIdFromFormFields("");

          // Need to remove the asset from Redux
          dispatch(assignReceivePanelAssetId(""));

          // Need to clear all the form values that might of been put in
          clearFormFields();
        } else {
          setState((prevState) => ({
            ...prevState,
            snackbarShow: true,
            snackbarSeverity: "error",
            snackbarText: res.error,
          }));
        }
      }
    );
  }

  // The useEffect grabs the selected forms fieldList and throws it into state
  useEffect(() => {
    if (action && forms && !form) {
      const formId = action.formId;

      // Grabbing the selected form
      const formTemp = forms.find((f) => f.formId === formId);

      // This statement sets up the formFields
      if (formTemp) {
        dispatch(setForm(formTemp));

        // Does the form have fields?
        if (formTemp.fields) {
          const collectionOfFields = {};

          formTemp.fields.forEach((field, index) => {
            const fieldCopy = { ...field };
            // We first check to see if the item is comign from a classification
            if (
              fieldCopy.fieldKey === "classificationMap" &&
              fieldCopy.topLevel &&
              classificationMap
            ) {
              const associatedClassification =
                asset.classificationMap[fieldCopy.topLevel];
              if (associatedClassification) {
                const fo = field.formOptions.find(
                  (fo) => fo.label === associatedClassification
                );

                if (fo) {
                  fieldCopy.fieldValue = fo.formOptionId;
                }
              }
            }

            collectionOfFields[index] = fieldCopy;
          });
          setFormFields(collectionOfFields);
        }
      }
    }
  }, [
    action,
    asset?.classificationMap,
    classificationMap,
    dispatch,
    form,
    forms,
  ]);

  // If the user clicks the back button or refreshes the page, we need to delete the assetId that was created
  useEffect(() => {
    return () => {
      if (assetIdFromToddsEndpoint.length > 0) {
        cancelAction();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="div"
      sx={{
        alignItems: "center",
        padding: "10px",
      }}
    >
      {/* If no event is present, we are forcing them back to the eventList 
      page to avoid any accidental scans with the wrong event */}
      {action ? null : <Navigate to={"/eventList"} />}

      {/* Something weird was going on with adding this to the ternary above so i broke it out
      to its own line - Handles when the user clicks the  "Go back to event list" button - Is it pretty? Nah, but it works ;) */}
      {navigateToEventListPage ? <Navigate to={"/eventList"} /> : null}

      {/* Success Message Snackbar */}
      <MaterialSnackbar
        open={snackbarShow}
        handleClose={() =>
          setState((props) => ({ ...props, snackbarShow: false }))
        }
        severity={snackbarSeverity}
        text={snackbarText}
      />
      <Grid container>
        {/* Item Label */}
        <Grid item xs={12}>
          <FormFieldText
            border="outlined-basic"
            handleFieldValue={(index, label) => {
              setState((prevState) => ({ ...prevState, itemLabel: label }));
            }}
            label="Receiving label"
            onClear={() => {
              setState((prevState) => ({ ...prevState, itemLabel: "" }));
            }}
            readOnly={showFormFields}
            value={itemLabel}
            variant="outlined"
          />
        </Grid>

        {/* Form Fields */}
        {showFormFields ? (
          <Grid item xs={12}>
            {fieldComps ? fieldComps : null}
          </Grid>
        ) : null}

        {/* Buttons Container */}
        <Box
          backgroundColor="white"
          bottom="0"
          display="flex"
          justifyContent="space-between"
          position="sticky"
          width="100%"
        >
          {/* Submit Button */}
          <Grid item xs={4.5}>
            <Button
              disabled={
                itemLabel.length === 0 ||
                (itemLabel.length > 0 &&
                  showFormFields &&
                  assetInfo.success === undefined)
              }
              fullWidth
              onClick={(e) => {
                if (!showFormFields && itemLabel.length !== 0) {
                  externalScanFromApp({
                    assetMode: "Inventory",
                    externalId: itemLabel,
                    token,
                  }).then((res) => {
                    if (res.success) {
                      setValues(res);
                    } else {
                      handleSnackbar(
                        "error",
                        `Something went wrong. ${res?.error ? res?.error : ""}`
                      );
                    }
                  });
                } else {
                  handleSubmit();
                }
              }}
              sx={{ mt: 3, mb: 2 }}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>

          {/* Go Back to Event List Page Button */}

          {showFormFields ? null : (
            <Grid item xs={7}>
              <Button
                fullWidth
                onClick={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    navigateToEventListPage: true,
                  }));
                }}
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
              >
                Event List
              </Button>
            </Grid>
          )}

          {/* Cancel Button */}
          {showFormFields ? (
            <Grid item xs={4}>
              <Button
                fullWidth
                onClick={() => {
                  cancelAction();
                }}
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
          ) : null}
        </Box>
      </Grid>
    </Box>
  );
}
