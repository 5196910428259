import { useCallback, useEffect, useState } from "react";
import { trimmedLink } from "../../../utils/extractAssetId";
import { getAsset } from "../../../utils/api";
import { Grid, TextField, Typography } from "@mui/material";
import debounce from "lodash.debounce";
import InfoIcon from "@mui/icons-material/Info";
import InformationSlide from "./InformationSlide";

export default function AssetIdTextField(props) {
  const {
    assetInfo,
    cypressId,
    formFields,
    label,
    multiline = false,
    onChange,
    onClear,
    readOnly = false,
    setAssetInfo,
    showInfoIcon = true,
    type = "text",
    token,
    value = "",
  } = props;
  const [open, setOpen] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const { asset = {} } = assetInfo;
  const { assets = [], tag = null } = asset;

  const handleClickOpen = () => {
    setOpen(true);
  };

  function extrapolateAssetId(event) {
    const eventString = event.target.value;
    // We steralize the link
    const steralizedLink = trimmedLink(eventString);

    // We then turn it into an array (This is for when people paste the url in)
    const steralizedLinkSplit = steralizedLink.split("");

    // We then figure out if it is http or https
    const isHttp = steralizedLink.substring(0, 4) === "http";
    const isHttps = steralizedLink.substring(0, 5) === "https";

    // We then pull the url out of the array
    const extratedUrl = isHttps
      ? steralizedLinkSplit.slice(0, 61).join("")
      : steralizedLinkSplit.slice(0, 60).join("");
    let assetId = null;

    // Once the url is long enough to extract an assetID, we do so. And only do so once
    if (isHttps && extratedUrl.length === 61) {
      assetId = steralizedLink.substring(25, 61);
    } else if (isHttp && !isHttps && extratedUrl.length === 60) {
      assetId = steralizedLink.substring(24, 60);
    }

    // Is the assetId valid?
    const isValidAssetId = assetId !== null && assetId?.length === 36;

    if (isHttps && isValidAssetId && extratedUrl?.length === 61) {
      if (Object.keys(assetInfo).length === 0) {
        getAsset({ assetId: assetId, token }).then((res) => {
          setAssetInfo(res);
          onChange({ target: { assetInfo:res, value: assetId } });
        });
      }
      onChange(event);
    } else if (isHttp && isValidAssetId && extratedUrl?.length === 60) {
      getAsset({ assetId: assetId, token }).then((res) => {
        setAssetInfo(res);
        onChange({ target: { assetInfo:res, value: assetId } });
      });
    } else {
      onChange(event);
    }
  }

  useEffect(() => {
    // There will be no info to show if there are no children. Thus we
    // remove the icon if none are present
    if (!showInfoIcon || assets.length === 0) {
      setShowInformation(false);
    } else {
      setShowInformation(true);
    }
  }, [assets.length, showInfoIcon]);

  const debouncedChangeHandler = useCallback(
    debounce(extrapolateAssetId, 750),
    []
  );

  return (
    <Grid container>
      {/* Information Slide */}
      {showInformation ? (
        <InformationSlide
          assetInfo={assetInfo}
          formFields={formFields}
          open={open}
          setOpen={setOpen}
        />
      ) : null}

      {/* Title */}
      <Grid item xs={12}>
        <Typography
          align="left"
          component="div"
          display="flex"
          fontSize="14px"
          variant="subtitle1"
        >
          {label}{" "}
          {showInformation ? (
            <InfoIcon
              color={tag?.length > 0 ? "inherit" : "disabled"}
              fontSize="small"
              sx={{
                cursor: "pointer",
                marginLeft: ".2rem",
              }}
              readOnly={true}
              onClick={() => {
                if (tag.length > 0) {
                  handleClickOpen();
                } else {
                  return;
                }
              }}
            />
          ) : null}
        </Typography>
      </Grid>

      {/* Debounce Test */}
      {/* <input type="text" onChange={debouncedChangeHandler} /> */}

      {/* Input */}
      <Grid item xs={12} sx={{ pb: 2 }}>
        {readOnly ? (
          <Typography variant="subtitle2">{value}</Typography>
        ) : (
          <TextField
            disabled={readOnly}
            id="outlined-basic"
            label=""
            inputProps={{ inputMode: "none", "data-cypress-id": cypressId || "" }}
            fullWidth
            multiline={multiline}
            // onChange={(event) => {
            //   // If a device has already been detected, this will clear it out so the
            //   // user can enter in another tag
            //   if (tag !== null) {
            //     // We clear out the asset
            //     onClear();
            //   } else {
            //     extrapolateAssetId(event);
            //   }
            // }}
            onChange={(event) => {
              // If a device has already been detected, this will clear it out so the
              // user can enter in another tag
              if (tag !== null) {
                // We clear out the asset
                onClear();
              } else {
                onChange(event)
                debouncedChangeHandler(event)
              }
            }}
            type={type}
            value={tag ? tag : value}
          />
        )}
      </Grid>
    </Grid>
  );
}
