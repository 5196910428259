import { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  List,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  Toolbar,
  ListItemText,
  Typography,
} from "@mui/material";
import { FormPanel } from "../features/panels/Form Panel/FormPanel";
import { EventList } from "../features/panels/EventList/EventList";
import { Routes, Route, Link } from "react-router-dom";
import { selectApplicationTitle } from "../features/panels/panelSlice";
import { useSelector } from "react-redux";
import MainPanel from "../features/panels/Main Panel/MainPanel";
import MenuIcon from "@mui/icons-material/Menu";
import ReceivePanel from "../features/panels/Receive Panel/Receive";
import { selectToken } from "../features/auth/authSlice"
import TreeMap from "../features/panels/Aggregation Panel/TreeMap";

export function MainView(props) {
  const { dispatch, handleLogout, window } = props;
  const applicationTitle = useSelector(selectApplicationTitle);
  const token = useSelector(selectToken)
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const commonComponentProps = { token }

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        textAlign: "center",
      }}
    >
      {/* Nav Links */}
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <Link
              to="/eventList"
              style={{ textDecoration: "none", width: "100%" }}
            >
              <ListItemText onClick={() => {}} primary="Event List" />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <Link
              to="/itemAggregation"
              style={{ textDecoration: "none", width: "100%" }}
            >
              <ListItemText onClick={() => {}} primary="Item Aggregation" />
            </Link>
          </ListItemButton>
        </ListItem>
      </List>

      {/* Logout Button */}
      <Box mb={3}>
        <Button
          onClick={(e) => {
            handleLogout(e);
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Log out
        </Button>
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={2}>
      {/* Upper Nav Bar */}
      <AppBar component="nav">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {applicationTitle}
          </Typography>
          <IconButton
            aria-label="menu"
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            size="large"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Side Nav Bar */}
      <Box component="nav">
        <Drawer
          container={container}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          onClose={handleDrawerToggle}
          open={mobileOpen}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          variant="temporary"
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Routes */}

      <Routes>
        {/* Main Page */}
        <Route path="/" element={<MainPanel {...commonComponentProps} />} />
        <Route path="" element={<MainPanel {...commonComponentProps} />} />

        {/* Event List */}
        <Route path="/eventList" element={<EventList {...commonComponentProps} dispatch={dispatch} />} />
        
        {/* Forms Page */}
        <Route path="/forms" element={<FormPanel {...commonComponentProps} />} />
        
        {/* Item Aggregation */}
        <Route path="/itemAggregation" element={<TreeMap {...commonComponentProps} />} />

        {/* Receive Page - We do NOT want to hard code this, but plan to undo This
        work around as we mature the forms endpoint */}
        <Route path="/receive" element={<ReceivePanel {...commonComponentProps} />} />
      </Routes>

    </Grid>
  );
}
